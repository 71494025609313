<template>
  <div class="game-container" ref="container"></div>
</template>

<script>

import SvgBoard from 'fourengine/js/SvgBoard.js';
import GameModel from 'fourengine/js/GameModel.js';

export default {
  name: 'Game',

  data() {
    return {
      game: new GameModel(),
    }
  },

  mounted() {
    const board = new SvgBoard({
      container: this.$refs.container,
      game: this.game,
    });
    board.drawBoard();
  },

  methods: {

  }
}

</script>

<style>
  .game-container {
    margin-top: 20px;
  }

  .game-container > * {
    margin: 0 auto;
  }
</style>
